import {useLayoutStore} from "../store/layout";

export const $getCurrentModule = () => {
    const module = useLayoutStore().currentModule
    return module ? appModules[module] : null
}

export const $getModuleIcon = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.icon ?? null
    return appModules[module]?.icon ?? null
}

export const $getModuleColor = (module?: string): string | null => {
    if (!module) return $getCurrentModule()?.color ?? null
    return appModules[module]?.color ?? null
}

export const appModules: AppModules = {
    dashboard: {
        name: 'dashboard',
        icon: 'heroicons:home',
        color: 'primary'
    },
    users: {
        name: 'users',
        icon: 'heroicons:users',
        color: 'primary',
        search: {
            detail: 'service-settings-users-id-userBaseData',
            nameProp: 'fullname'
        },
    },
    organisations: {
        name: 'organisations',
        icon: 'heroicons:user',
        color: 'primary',
        search: {
            detail: 'service-settings-organisations-id-organisationBaseData',
            nameProp: 'name'
        },
    },
    processes: {
        name: 'processes',
        icon: 'heroicons:clipboard-document-list',
        color: 'primary'
    },
    statistics: {
        name: 'statistics',
        icon: 'heroicons:presentation-chart-bar',
        color: 'primary'
    },
    payments: {
        name: 'payments',
        icon: 'heroicons:banknotes',
        color: 'primary'
    },
    paymentRuns: {
        name: 'paymentRuns',
        icon: 'heroicons:document-currency-euro-solid',
        color: 'primary'
    },
    settings: {
        name: 'settings',
        icon: 'heroicons:cog-6-tooth',
        color: 'primary'
    },
    basicData: {
        name: 'basicData',
        icon: 'heroicons:wrench-screwdriver',
        color: 'primary'
    },
    logout: {
        name: 'logout',
        icon: 'heroicons:arrow-right-on-rectangle',
        color: 'primary'
    },
    jugendzaehlt: {
        name: 'jugendzaehlt',
        icon: 'heroicons:adjustments-horizontal-16-solid',
        color: 'primary'
    },
    regions: {
        name: 'regions',
        icon: 'heroicons:adjustments-horizontal-16-solid',
        color: 'primary'
    },
    parishes: {
        name: 'parishes',
        icon: 'heroicons:adjustments-horizontal-16-solid',
        color: 'primary'
    },
}
